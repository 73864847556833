import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// this custom Link handles both internal and external links

const Link = (props) => {
  const { to, children, activeClassName, partiallyActive, ...other } = props

  const isExternalLink = (text) => {
    return /http/i.test(text)
  }

  return (
    <>
      {isExternalLink(to) ? (
        <a href={to} {...other} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <GatsbyLink
          to={to}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </GatsbyLink>
      )}
    </>
  )
}

export default Link
