import React from 'react'
import MapSection from '../components/HundredTop/MapSection/MapSection'

const States = () => {
  const style = {
    background: '#fff',
    border: '1px solid gray',
  }

  return (
    <>
      <div className="states" style={style}>
        <MapSection />
      </div>
    </>
  )
}

export default States
